import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

// API
import { getAllHosts } from '@/api/tango/database';

// Constants
import { DB_GET_HOSTS_TIMING } from '@/data/constants';

// Polka state
import { appStateDispatchContext } from '@/state/AppState';

// Types
import type { IHostInfo } from '@/api/tango/database';
import type { IDataFetchState } from '@/state/types';

interface IHostProviderProps {
    children: JSX.Element;
}

/**
 * Context provider that periodically fetch all
 * hosts
 *
 * @param children All other React children
 */
const HostsProvider = ({ children }: IHostProviderProps) => {
    // Dispatch global state
    const dispatch = useContext(appStateDispatchContext);
    const dispatchHosts = (hosts: IDataFetchState<IHostInfo[]>) =>
        dispatch({ type: 'CHANGE_HOST_LIST', newHosts: hosts });

    // Periodically fetch
    const { data, error, isLoading } = useQuery({
        queryKey: ['HostsProvider'],
        queryFn: async () => await getAllHosts(),
        refetchInterval: DB_GET_HOSTS_TIMING,
    });

    // Change state every time new data arrive
    useEffect(() => {
        if (data) {
            dispatchHosts({
                data,
                error,
                isLoading,
            });
        }
    }, [data, error, isLoading]);

    return children;
};

export default HostsProvider;
