import { jwtDecode } from 'jwt-decode';

// API
import { extend } from '@/api/auth/extend';

// Types
import type { ILoginInfo } from '@/api/auth/login';
import type { ISessionType } from './Session';

type UpdateSessionFnT = (data: ISessionType) => void;

/**
 * Given a cookie key `name`, returns the value of
 * the cookie or `null`, if the key is not found.
 *
 * @param name Name of the cookie
 *
 * @returns Cookie content string or null on not found
 */
export function getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
        document.cookie
            .split(';')
            .map((c) => c.trim())
            .filter((cookie) => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map((cookie) => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null
    );
}

/**
 * Get expiration time from JWT and calculate the
 * expiration date in milliseconds, based on current
 * time
 *
 * @returns Timestamp of JWT expiration date form current time
 */
export function getDelayFromCookieToken(): number {
    const tarantaJWT = getCookie('taranta_jwt');

    if (tarantaJWT) {
        const decodedPayload = jwtDecode<ILoginInfo>(tarantaJWT);

        const currentDate = new Date().getTime();
        const expireDate = decodedPayload.exp * 1000;

        const delay = expireDate - currentDate;
        return delay;
    }
    throw new Error('getDelayFromCookieToken(): taranta_jwt is empty');
}

/**
 * Start periodically refreshing JWT
 *
 * @param updateSession Function fo updating session context
 * @param delay Interval of refreshing the token\
 *
 * @returns Interval object
 */
export function startRefreshingToken(
    updateSession: UpdateSessionFnT,
    delay: number,
) {
    const refresh = async () => await refreshToken(updateSession);

    const interval = setInterval(refresh, delay);
    return interval;
}

/**
 * Refresh token once
 *
 * @param updateSession Update session state function
 */
export async function refreshToken(updateSession: UpdateSessionFnT) {
    try {
        // Taranta Auth returns null on invalid token,
        // and we throw an error when it happens...
        await extend();

        // otherwise, it sets the cookie for us, so we just check
        // if it really exist in the browser...
        const tarantaJWT = getCookie('taranta_jwt');
        if (tarantaJWT) {
            const decodedPayload = jwtDecode<ILoginInfo>(tarantaJWT);

            // Update session
            updateSession({ isLoggedIn: true, data: decodedPayload });
        }
    } catch (error) {
        console.log('refreshToken(): ', error);
    }
}
