import { JWT_COOKIE_NAME } from '@/data/constants';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
export interface ILoginInfo {
    username: string;
    groups: Array<string>;
    iat: number;
    exp: number;
}

/**
 * Login user with given credintials
 *
 * @param username
 * @param password
 *
 * @returns login info ot throw error
 */
export async function login(
    username: string,
    password: string,
): Promise<ILoginInfo> {
    const loginCookies = new Cookies();
    loginCookies.remove(JWT_COOKIE_NAME, { path: '/' });

    const fetchSettings = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ username, password }),
    };

    return await fetch('auth/login', fetchSettings)
        .then((response) => response.json())
        .then(({ taranta_jwt }) => {
            loginCookies.set(JWT_COOKIE_NAME, taranta_jwt, { path: '/' });

            const decodedPayload = jwtDecode<ILoginInfo>(taranta_jwt);

            return decodedPayload;
        })
        .catch((error) => {
            if (error instanceof SyntaxError)
                throw new Error('Wrong username or password');
            throw error;
        });
}
