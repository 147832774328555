import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    type ColumnDef,
    type PaginationState,
    type SortingState,
} from '@tanstack/react-table';

import BaseTable from './BaseTable';

interface IManuallyPaginatedTableProps<T extends object> {
    /** Column definitions specifying how to render and handle each column */
    columns: ColumnDef<T>[];
    /** Current page data received from the server */
    data: T[];

    /** Table state */
    pagination: PaginationState;
    sorting: SortingState;

    /** Total number of pages available from the server */
    pageCount: number;
    /** Total number of rows available across all pages */
    totalRows: number;
    /** Flag indicating if data is being fetched */
    isLoading?: boolean;
    /** Callback fired when pagination state changes to fetch new data */
    onPaginationChange: (pagination: PaginationState) => void;
    /** Optional callback fired when sorting state changes to fetch sorted data */
    onSortingChange: (sorting: SortingState) => void;
    /** Message to display when there are no rows in the table */
    emptyMessage?: string;
}

/** Table component that implements server-side pagination with optional sorting support.
 * Suitable for large datasets where data needs to be fetched in chunks from a server.
 */
const ManuallyPaginatedTable = <T extends object>(
    props: IManuallyPaginatedTableProps<T>,
): JSX.Element => {
    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        pageCount: props.pageCount,
        state: {
            sorting: props.sorting,
            pagination: props.pagination,
        },
        onSortingChange: (updater) => {
            const newSorting =
                typeof updater === 'function'
                    ? updater(props.sorting)
                    : updater;
            props.onSortingChange?.(newSorting);
        },
        onPaginationChange: (updater) => {
            const newPagination =
                typeof updater === 'function'
                    ? updater(props.pagination)
                    : updater;
            props.onPaginationChange(newPagination);
        },
        manualPagination: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <BaseTable
            table={table}
            columns={props.columns}
            isLoading={props.isLoading}
            totalRows={props.totalRows}
            emptyMessage={props.emptyMessage}
        />
    );
};

export default ManuallyPaginatedTable;
