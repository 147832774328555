import { get } from 'radash';

// Context
import { useSession } from '@/context/Session';

// Types
import type { ISessionType } from '@/context/Session';

// Components
import LogIn from './LogIn/LogIn';
import LogOut from './LogOut/LogOut';

// SCSS
import './Auth.scss';

/**
 * Display login or logout button according to current session
 * status
 */
const Auth = () => {
    const session = useSession();

    const user = get<ISessionType, string>(session, 'data.username');
    return session.isLoggedIn ? <LogOut user={user} /> : <LogIn />;
};

export default Auth;
