import { useQuery } from '@tanstack/react-query';

// API
import * as TangoHost from '@/api/tango/host';

/**
 * Handle fetching Tango host information
 *
 * @param hostName
 * @returns Host information
 */
function fetchFunction(hostName: string) {
    const response = TangoHost.getInfo(hostName);
    return response;
}

/**
 * Fetch information about host
 *
 * @param hostName
 * @returns Host information
 */
export function useHostInfo(hostName: string) {
    return useQuery({
        queryKey: ['useHostInfo'],
        queryFn: () => fetchFunction(hostName),
    });
}
