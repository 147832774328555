import { createMenuOptions } from './libInstanceMenu';

// Types
import type { IServerInfo } from '@/api/subscription';

// Components
import KebabMenu from '@/components/KebabMenu/KebabMenu';

interface IServerInstanceMenuProps {
    serverInfo: IServerInfo;
}

/**
 * Kebab menu with all actions we can make on server
 *
 * @param serverInfo information about particular server
 */
const ServerInstanceMenu = ({ serverInfo }: IServerInstanceMenuProps) => {
    const menuOptions = createMenuOptions(serverInfo);
    return <KebabMenu options={menuOptions} />;
};

export default ServerInstanceMenu;
