import { useMemo } from 'react';

// Components
import PrettyDate from '@/components/PrettyDate/PrettyDate';
import AutoPaginatedTable from '@/components/Table/AutoPaginatedTable';

// Types
import type { IStarterLogs } from '@/api/tango/host';
import type { ColumnDef } from '@tanstack/react-table';

interface IStarterLogViewerProps {
    starterLogs: IStarterLogs[];
}

/**
 * Display table with all starter logs
 *
 * @param starterLogs - table with all starter logs
 */
const StarterLogViewer = ({ starterLogs }: IStarterLogViewerProps) => {
    const columns = useMemo<ColumnDef<IStarterLogs>[]>(
        () => [
            {
                accessorKey: 'date',
                header: 'Date',
                sortingFn: 'datetime',
                sortDescFirst: true,
                cell: (info) => {
                    const dateString = info.getValue<string>();

                    const [day, month, year] = dateString
                        .split('/')
                        .map(Number);
                    const date = new Date(2000 + year, month - 1, day);

                    return <PrettyDate date={date} />;
                },
            },
            {
                accessorKey: 'time',
                header: 'Time',
                sortDescFirst: true,

                // Time that we get from Starter match the
                // format we display in <PrettyTime /> component,
                // so there is no need to create any custom here.
                //cell: (info) => {},
            },
            {
                accessorKey: 'message',
                header: 'Message',
                sortUndefined: 'last',
            },
        ],
        [],
    );

    return (
        <AutoPaginatedTable<IStarterLogs>
            columns={columns}
            data={starterLogs}
            emptyMessage="It looks like there is no logs"
        />
    );
};

export default StarterLogViewer;
