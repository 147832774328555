interface IPrettyDateProps {
    date: Date | number | string;
    isSeconds?: boolean;
}

/**
 * Display given date in a nice and pretty way
 *
 * @param seconds The seconds from statistics
 */
const PrettyDate = ({ date, isSeconds }: IPrettyDateProps) => {
    let dateObj: Date;
    if (typeof date == 'number') {
        const multiplier = isSeconds ? 1000 : 1;
        dateObj = new Date(date * multiplier);
    } else {
        dateObj = new Date(date);
    }

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    // Date
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();

    return (
        <>
            {day} {monthNames[month - 1]} {year}
        </>
    );
};

export default PrettyDate;
