// API
import Loading from '@/components/Loading/Loading';

// Hooks
import { useDbInfo } from '@/hooks/useDbInfo/useDbInfo';

/**
 * Display information about database
 *
 * Information looks like this:
 *
 * TANGO Database sys/database/2
 * Running since 2023-06-14 13:27:36
 * Devices defined = 26
 * Devices exported = 21
 * Device servers defined = 13
 * Device servers exported = 11
 * Device properties defined = 52 [History lgth = 108]
 * Class properties defined = 79 [History lgth = 113]
 * Device attribute properties defined = 0 [History lgth = 0]
 * Class attribute properties defined = 0 [History lgth = 0]
 * Object properties defined = 0 [History lgth = 0]
 */
const DbInfo = () => {
    const { data, error, isLoading } = useDbInfo();
    if (error) return <p>{error.message}</p>;
    else if (isLoading) return <Loading />;

    if (!data) return <></>;
    return (
        <div>
            {data.map((line: string, index: number) => {
                return <p key={index}>{line}</p>;
            })}
        </div>
    );
};

export default DbInfo;
