// Types
import type { IServerInfo } from '@/api/subscription';
import type { IMenuOptions } from '@/components/KebabMenu/KebabMenu';

import createConfigureWithTaranta from '../InstanceDialogs/ConfigureWithTaranta';
import createExportDeviceServer from '../InstanceDialogs/ExportDeviceServer/ExportDeviceServer';
import createKillServer from '../InstanceDialogs/KillServer';
import createChangeHost from '../InstanceDialogs/MoveToHost/ChangeHost';
import createPollingConfigurator from '../InstanceDialogs/PollingConfigurator/PollingConfigurator';
import { createPollingProfiler } from '../InstanceDialogs/PollingProfiler/PollingProfiler';
import createPollingStatus from '../InstanceDialogs/PollingStatus/PollingStatus';
import createPollingThreadsManager from '../InstanceDialogs/PollingThreadsManager/PollingThreadsManager';
import createRestartServer from '../InstanceDialogs/RestartServer';
import createSetStartupLevel from '../InstanceDialogs/SetStartupLevel/SetStartupLevel';
import createStartServer from '../InstanceDialogs/StartServer';

export function createMenuOptions(serverInfo: IServerInfo): IMenuOptions {
    return {
        'Start server': createStartServer(serverInfo),
        'Kill server': createKillServer(serverInfo),
        'Restart server': {
            ...createRestartServer(serverInfo),
            disabled: serverInfo.state !== 'ON',
        },
        'Set startup level': createSetStartupLevel(serverInfo),
        'divider-1': 'divider',
        'Configure polling': {
            ...createPollingConfigurator(serverInfo),
            disabled: serverInfo.state !== 'ON',
        },
        'Polling profiler': {
            ...createPollingProfiler(serverInfo),
            disabled: serverInfo.state !== 'ON',
        },
        'Polling status': {
            ...createPollingStatus(serverInfo),
            disabled: serverInfo.state !== 'ON',
        },
        'Polling threads manager': {
            ...createPollingThreadsManager(serverInfo),
            disabled: serverInfo.state !== 'ON',
        },
        'divider-2': 'divider',
        'Move to different host': createChangeHost(serverInfo),
        ...((window?.POLKA_CONFIG?.databases?.length ?? 0) >= 2 && {
            'Export device server': createExportDeviceServer(serverInfo),
        }),
        ...(window.POLKA_CONFIG?.taranta_url && {
            'Configure with Taranta': createConfigureWithTaranta(serverInfo),
        }),
    };
}
