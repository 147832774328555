// Types
import type { IRawStarterStatisticsResponse } from '@/hooks/useStatistics/libUseStatistics';

export interface IDurationTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export interface IStatisticsByServers {
    [key: string]: Array<IRawStarterStatisticsResponse>;
}

/**
 * Groups raw statistics entries by their device names.
 *
 * @param rawStatistics statistics we want to group
 *
 * @returns Grouped statistics by device name
 */
export function groupStatisticsByServers(
    rawStatistics: Array<IRawStarterStatisticsResponse>,
): IStatisticsByServers {
    const serverMapper: IStatisticsByServers = {};

    for (const statisticsEntry of rawStatistics) {
        const { deviceName } = statisticsEntry;

        // Ensure deviceName exists as a key in serverMapper, and initialize it if necessary
        if (!serverMapper[deviceName]) {
            serverMapper[deviceName] = [];
        }

        // Add the statistics entry to the array
        serverMapper[deviceName].push(statisticsEntry);
    }

    return serverMapper;
}
