import { HOST_GET_INFO_QUERY } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import type { IHostGetInfoDTO } from '@/api/gql/types';
import type { stateType } from '../../types';

export interface IHostGetInfo {
    name: string;
    state: stateType;
    connected: boolean;
    server: {
        id: string;
        host: string;
    };
    pid: number;
    startedDate: string;
    stoppedDate: string;
    exported: boolean;
}

export async function getInfo(host: string): Promise<IHostGetInfo> {
    const devName = `dserver/starter/${host}`;

    const response = await gqlRequest<IHostGetInfoDTO>(HOST_GET_INFO_QUERY, {
        name: devName,
    });
    return response.device;
}
