// Utils
import { extractStateFromRawString } from '@/api/utils/stateUtils';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';
import { useHostInfo } from '@/hooks/useHostInfo/useHostInfo';

// Components
import Loading from '@/components/Loading/Loading';

/**
 * Display information about host
 * Information looks like this:
 *
 * Device: dserver/starter/tangobox
 * State: ON
 * Exported: true
 * Connected: true
 * Server: Starter/tangobox
 * Host: tangobox
 * PID: 2306
 * Start date: 15th June 2023 at 08:57:10
 * Stop date: 22nd May 2023 at 15:11:53
 */
const HostInfo = () => {
    const { selectedHost } = useAppContext();

    const { data, error, isLoading } = useHostInfo(selectedHost);

    if (error) return <p>Error: {error.message}</p>;
    else if (isLoading) return <Loading />;
    if (!data) return <></>;

    const hostInfo = data;
    const BasicData = (
        <>
            <p>Device: {hostInfo.name}</p>
            <p>State: {hostInfo.state}</p>
            <p>Exported: {hostInfo.exported.toString()}</p>
        </>
    );

    const deviceState = extractStateFromRawString(hostInfo.state);

    if (deviceState === 'ON') {
        // When server didn't stop, 'stoppedDate' is empty
        const StopDate = () => {
            if (hostInfo.stoppedDate)
                return <p>Stop date: {hostInfo.stoppedDate}</p>;
            else return <></>;
        };

        return (
            <div>
                {BasicData}
                <p>Connected: {hostInfo.connected.toString()}</p>
                <p>Server: {hostInfo.server.id}</p>
                <p>Host: {hostInfo.server.host}</p>
                <p>PID: {hostInfo.pid}</p>
                <p>Start date: {hostInfo.startedDate}</p>

                <StopDate />
            </div>
        );
    } else return <div>{BasicData}</div>;
};

export default HostInfo;
