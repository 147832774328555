import { gqlRequest } from '@/api/gql/request';

import { DB_GET_INFO_STRING_QUERY } from '@/api/gql/query';

import { getCurrentDB } from '@/utils/database';

// Types
import type { IDatabaseGetInfoStringDTO } from '@/api/gql/types';

/**
 * fetch information about Tango database
 *
 * @returns String with information
 */
export async function getInfoString(tangoDb = getCurrentDB()) {
    const response = await gqlRequest<IDatabaseGetInfoStringDTO>(
        DB_GET_INFO_STRING_QUERY,
        {},
        { tangoDb },
    );
    return response.info;
}
