// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Hooks
import { useStarterLogs } from '@/hooks/useStarterLogs/useStarterLogs';

// Component
import Loading from '@/components/Loading/Loading';
import StarterLogViewer from './StarterLogViewer/StarterLogViewer';

/**
 * Display logs of particular Tango Starter
 */
const HostStarterLogs = () => {
    const { selectedHost } = useAppContext();

    const { data, error, isLoading } = useStarterLogs(selectedHost);

    if (error) return <p>{error.message}</p>;
    else if (isLoading) return <Loading />;
    if (!data) return <></>;

    return <StarterLogViewer starterLogs={data} />;
};

export default HostStarterLogs;
