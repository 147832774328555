import { createContext, useContext, useEffect, useState } from 'react';

// API

// Types
import type { authUserResponseType } from '@/api/auth/user';
import {
    getCookie,
    getDelayFromCookieToken,
    refreshToken,
    startRefreshingToken,
} from './libSession';

// Session context
export interface ISessionType {
    isLoggedIn: boolean;
    data: authUserResponseType;
}

const SessionContext = createContext<ISessionType>({
    isLoggedIn: false,
    data: null,
});

type changeSessionContextType = React.Dispatch<ISessionType>;
export const ChangeSessionContext = createContext<changeSessionContextType>(
    () => null,
);

// Token extend context
type setIntervalT = ReturnType<typeof setInterval>;
export const TokenExtendIntervalContext = createContext<setIntervalT | null>(
    null,
);

type changeTokenExtendIntervalT = React.Dispatch<setIntervalT | null>;
export const ChangeTokenExtendIntervalContext =
    createContext<changeTokenExtendIntervalT>(() => null);

interface ISessionProviderProps {
    children: React.ReactElement;
}

/**
 * Context provider for session
 *
 * @param children
 */
export const SessionProvider = ({ children }: ISessionProviderProps) => {
    const [sessionState, setSessionState] = useState<ISessionType>({
        isLoggedIn: false,
        data: null,
    });
    const [tokenExtendInterval, setTokenExtendInterval] =
        useState<setIntervalT | null>(null);

    useEffect(() => {
        const updateToken = async () => {
            refreshToken(setSessionState);

            const delay = getDelayFromCookieToken();

            // Get interval for further manual stop
            const interval = startRefreshingToken(
                setSessionState,
                delay - 1000,
            );

            // Save interval in context
            setTokenExtendInterval(interval);
        };

        // Check if we already have a token and try to extend
        // it using Taranta Auth
        const jwt = getCookie('taranta_jwt');
        if (jwt) updateToken();
    }, []);

    return (
        <TokenExtendIntervalContext.Provider value={tokenExtendInterval}>
            <ChangeTokenExtendIntervalContext.Provider
                value={setTokenExtendInterval}
            >
                <SessionContext.Provider value={sessionState}>
                    <ChangeSessionContext.Provider value={setSessionState}>
                        {children}
                    </ChangeSessionContext.Provider>
                </SessionContext.Provider>
            </ChangeTokenExtendIntervalContext.Provider>
        </TokenExtendIntervalContext.Provider>
    );
};

export const useSession = () => useContext(SessionContext);
