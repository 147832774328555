import { useEffect, useMemo, useState } from 'react';

// API
import { changeThreadDeviceList } from '@/api/tango/server/changeThreadDeviceList/changeThreadDeviceList';

// Components
import Loading from '@/components/Loading/Loading';
import { createServerInstanceMenuItem } from '../instanceDialogs';
import ThreadsConfig from './ThreadsConfig/ThreadsConfig';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Hooks
import { useThreadManager } from '@/hooks/useThreadManager/useThreadManager';

// Types
import type { IServerInfo } from '@/api/subscription';
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';

interface IPollingThreadsManagerProps extends IDialogComponentProps {
    server: IServerInfo;
}

function PollingThreadsManager({
    server,
    isOpen,
    close,
}: IPollingThreadsManagerProps) {
    const [threads, setThreads] = useState<string[]>([]);

    const { data: rawThreadConfig, isLoading } = useThreadManager(server.name);

    const threadConfig = useMemo(() => {
        if (rawThreadConfig) {
            if (!rawThreadConfig || !rawThreadConfig[0]?.threadConfig?.length) {
                return [];
            }
            const threadConfig = rawThreadConfig[0].threadConfig[0].value;
            if (!rawThreadConfig[0].threadNumber?.length) {
                return threadConfig;
            }
            const declaredNumberOfThreads = Number.parseInt(
                rawThreadConfig[0].threadNumber[0].value[0],
            );
            threadConfig.length = declaredNumberOfThreads;
            threadConfig.fill('', threadConfig.length); // fill the array with empty strings to match the declared number of threads
            return threadConfig;
        } else return [];
    }, [rawThreadConfig]);

    // threadConfig serves as a default state of the threads state. Without this effect, it would be undefined.
    useEffect(() => {
        setThreads(threadConfig);
    }, [isLoading]);

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Manage polling threads</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Loading />
                ) : (
                    <ThreadsConfig
                        threads={threads}
                        onChangeThreads={setThreads}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>CANCEL</Button>
                <Button
                    variant="outlined"
                    onClick={() =>
                        setThreads((prevThreads) => prevThreads.concat(''))
                    }
                >
                    ADD THREAD
                </Button>
                <Button
                    variant="contained"
                    onClick={() => changeThreadDeviceList(server.name, threads)}
                >
                    SAVE
                </Button>
            </DialogActions>
        </Dialog>
    );
}
const createPollingThreadsManager = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, PollingThreadsManager, null);

export default createPollingThreadsManager;
