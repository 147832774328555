import { useState } from 'react';

// Hooks
import { useHostLogs } from '@/hooks/useHostLogs/useHostLogs';

// Components
import Loading from '@/components/Loading/Loading';
import LogViewer from './LogViewer/LogViewer';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Types
import type { PaginationState, SortingState } from '@tanstack/react-table';

/**
 * Fetch and display all logs that belong to the current host
 */
const HostLogs = () => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const { selectedHost } = useAppContext();
    const { data, error, isLoading } = useHostLogs(
        selectedHost,
        pagination.pageIndex + 1,
        pagination.pageSize,
    );

    if (error) return <p>{error.message}</p>;
    else if (isLoading) return <Loading />;
    if (!data) return <></>;

    return (
        <LogViewer
            pagination={pagination}
            sorting={sorting}
            response={data}
            setPagination={setPagination}
            setSearch={setSorting}
            isLoading={isLoading}
        />
    );
};

export default HostLogs;
