import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

// API
import { getAllDatabases } from '@/api/tango/database';

// Polka state
import { appStateDispatchContext } from '@/state/AppState';

// Constants
import { DB_GET_ALL_DB_TIMING } from '@/data/constants';

// Types
import type { IDatabaseInfo } from '@/api/tango/database';
import type { IDataFetchState } from '@/state/types';

interface IHostProviderProps {
    children: JSX.Element;
}

/**
 * Context provider that periodically fetch all
 * databaseds
 *
 * @param children All other React children
 */
export const DbProvider = ({ children }: IHostProviderProps) => {
    // Dispatch global state
    const dispatch = useContext(appStateDispatchContext);
    const dispatchDb = (db: IDataFetchState<IDatabaseInfo[]>) =>
        dispatch({ type: 'CHANGE_DB_LIST', newDb: db });

    // Periodically fetch
    const { data, error, isLoading } = useQuery({
        queryKey: ['DbProvider'],
        queryFn: async () => await getAllDatabases(),
        refetchInterval: DB_GET_ALL_DB_TIMING,
    });

    // Change state every time new data arrive
    useEffect(() => {
        if (data) {
            dispatchDb({
                data,
                error,
                isLoading,
            });
        }
    }, [data, error, isLoading]);

    return children;
};
