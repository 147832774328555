import { editHost } from '@/api/tango/editHost/editHost';
import { useHostsList } from '@/context/HostsContext/useHostsList';
import { useAppContext } from '@/hooks/state/useAppContext';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import HostChangeBranch from '../HostChangeBranch/HostChangeBranch';
import { EditHostFormSchema, type EditHostFormData } from './EditFormSchema';
import PathsInput from './PathsInput/PathsInput';

function HostEditProperties() {
    const { selectedHost } = useAppContext();
    const hostData = useHostsList().data.find(
        (host) => host.name === selectedHost,
    );
    const defaultValues: EditHostFormData = {
        branch: hostData?.branch || '',
        paths: new Set<string>(hostData?.startDsPath) || [],
        usage: hostData?.usage || '',
    };

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<EditHostFormData>({
        defaultValues,
        resolver: zodResolver(EditHostFormSchema),
    });

    const onSubmit = (data: EditHostFormData) => {
        editHost(selectedHost, data);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            }}
        >
            <Grid container spacing={2} padding={1}>
                <Grid item xs={12} md>
                    <Controller
                        control={control}
                        name="branch"
                        render={({
                            field: { value },
                            formState: { errors },
                        }) => (
                            <HostChangeBranch
                                branch={value}
                                onChangeBranch={(newBranch) =>
                                    setValue('branch', newBranch)
                                }
                                error={errors.branch?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <TextField
                        {...register('usage')}
                        label={'usage'}
                        error={!!errors['usage']}
                        helperText={errors['usage']?.message}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="paths"
                        render={({
                            field: { value },
                            formState: { errors },
                        }) => (
                            <PathsInput
                                paths={value}
                                onChangePaths={(newPaths) =>
                                    setValue('paths', newPaths)
                                }
                                error={errors.paths?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                        marginLeft: 'auto',
                    }}
                >
                    <ButtonGroup
                        orientation="vertical"
                        variant="contained"
                        fullWidth
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            SAVE
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </form>
    );
}

export default HostEditProperties;
