import type { IServersSubscriptionDTO } from '@/api/gql/types';
import type { IServerInfo } from '@/api/subscription';
import { parseServersResponse, ServerSubscription } from '@/api/subscription';
import type { IHostInfo } from '@/api/tango/database';
import { isEmpty } from 'radash';
import { useEffect } from 'react';
import { useHostsList } from '../HostsContext';
import { useServerListContext } from './useServerListContext';

export interface IServerList {
    [server: string]: IServerInfo[];
}

interface IProviderProps {
    children: JSX.Element;
    hostList: IHostInfo[];
}

const Provider = ({ hostList, children }: IProviderProps) => {
    const { dispatchServerList } = useServerListContext();

    useEffect(() => {
        const sub = new ServerSubscription();

        for (const host of hostList) {
            const fn = (rawData: unknown) => {
                interface IRawRespinse {
                    data: IServersSubscriptionDTO;
                }
                const rawResponse = rawData as IRawRespinse;
                const response = rawResponse.data;
                const servers = parseServersResponse(response);

                const stateObject: IServerList = {};
                stateObject[host.name] = servers;

                dispatchServerList(stateObject);
            };
            sub.subscribe(host.name, fn);
        }

        return () => sub.unsubscribeAll();
    }, []);
    return children;
};

interface IServerListProviderProps {
    children: JSX.Element;
}

export const ServerListProvider = ({ children }: IServerListProviderProps) => {
    const { data, isLoading } = useHostsList();

    if (isLoading || isEmpty(data)) return children;
    else return <Provider hostList={data}>{children}</Provider>;
};
