/**
 * Extend current token. If I understand the Taranta Auth
 * code correctly, it updates the token by sending the new one
 * with set_cookie, null otherwise.
 *
 * @returns successful message
 */
export async function extend() {
    const fetchSettings = { method: 'POST' };
    return await fetch('auth/extend', fetchSettings).then((response) => {
        const code = response.status;
        if (code === 403) throw new Error("Can't extend token");
        return 'Successfully extended';
    });
}
