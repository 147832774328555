import { getCurrentDB } from '@/utils/database';

// Types
import type { IFetchLogsResponseDTO, IResponseErrorDTO } from './types';

/**
 * Handler error from ua-logs response
 *
 * @param response response with the status code and message
 */
async function handleError(response: Response) {
    const error: IResponseErrorDTO = await response.json();
    throw new Error(`Error: ${response.status} ${error.message}`);
}

/**
 * Get hostname logs from ua-logs microservice
 *
 * @param deviceName Logs of what device we are looking for
 * @param page Which page we want to see
 * @param limit Page size
 *
 * @returns Logs stored in MongoDB
 */
export default async function fetchLogs(
    deviceName: string,
    page: number,
    limit = 10,
) {
    const beamline = getCurrentDB();
    const tangoDB = beamline ? `&tangoDB=${beamline}` : '';

    const response = await fetch(
        `/logs/userActionLogs?device=${deviceName}${tangoDB}&limit=${limit}&page=${page}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        },
    );

    if (response.status !== 200) await handleError(response);

    const logs: IFetchLogsResponseDTO = await response.json();
    return logs;
}
