// Error codes
export const STAT_FILE_NOT_FOUND = 'STAT_FILE_NOT_FOUND';
export const STAT_UNKNOWN = 'STAT_UNKNOWN';

export function errorMessageToErrorCode(message: string) {
    if (message == 'No such file or directory') return STAT_FILE_NOT_FOUND;
    else return STAT_UNKNOWN;
}

interface IServerStatisticsErrorProps {
    errorMessage: string;
}

/**
 * Display statistics error message
 *
 * @param message Error message
 */
const ServerStatisticsError = ({
    errorMessage,
}: IServerStatisticsErrorProps) => {
    const msg = errorMessage.replace('DevReadLog: ', '').split(';');

    const errorCode = errorMessageToErrorCode(msg[0]);

    // Those are all known errors
    switch (errorCode) {
        case STAT_FILE_NOT_FOUND:
            return <>Woopsi no statistics here</>;
        default:
            return <>Unknown error: {errorCode}</>;
    }
};

export default ServerStatisticsError;
