interface IPrettyTimeProps {
    date: Date | number | string;
    isSeconds?: boolean;
}

/**
 * Display time in a nice way
 *
 * @param date JS Date object
 * @param isSeconds When date is instance off number, this indicate if
 *                  is seconds or millisecond
 */
export const PrettyTime = ({ date, isSeconds }: IPrettyTimeProps) => {
    let dateObj: Date;
    if (typeof date == 'number') {
        const multiplier = isSeconds ? 1000 : 1;
        dateObj = new Date(date * multiplier);
    } else {
        dateObj = new Date(date);
    }

    // Time
    const hour = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const sec = String(dateObj.getSeconds()).padStart(2, '0');

    return (
        <>
            {hour}:{minutes}:{sec}
        </>
    );
};
