import { useEffect } from 'react';

// Context
import { useInstanceContext } from './useInstanceContext';

interface IInstanceProviderProps {
    children: JSX.Element;
}

/**
 * Context provider that periodically fetch all
 * server instances from Tango
 *
 * @param children All other React children
 */
export const InstanceProvider = ({ children }: IInstanceProviderProps) => {
    const { data, error, isLoading, dispatchInstances } = useInstanceContext();

    // Change state every time new data arrive
    useEffect(() => {
        if (data) {
            dispatchInstances({
                data,
                error,
                isLoading,
            });
        }
    }, [data, error, isLoading]);

    return children;
};
