import { useQuery } from '@tanstack/react-query';

// API
import * as TangoHost from '@/api/tango/host';

/**
 * Handle fetching starter logs
 *
 * @param hostName Starter name
 *
 * @returns Starter logs
 */
async function fetchFunction(hostName: string) {
    const response = await TangoHost.getStarterLogs(hostName);
    return response;
}

/**
 * Fetch Starter logs
 *
 * @param hostName Starter name
 */
export function useStarterLogs(hostName: string) {
    return useQuery({
        queryKey: ['useStarterLogs'],
        queryFn: () => fetchFunction(hostName),
    });
}
