import { useQuery } from '@tanstack/react-query';

// API
import fetchLogs from '@/api/mongo/logs/fetchLogs';

/**
 * Handle fetching host logs
 *
 */
function fetchFunction(hostName: string, pageNumber: number, pageSize: number) {
    const fullHostName = `tango/admin/${hostName}`;
    return fetchLogs(fullHostName, pageNumber, pageSize);
}

/**
 * Fetch page of logs for given hostname
 *
 * @param hostName The logs of which host we want to fetch
 * @param pageNumber
 *
 * @returns Host logs
 */
export function useHostLogs(
    hostName: string,
    pageNumber: number,
    pageSize: number,
) {
    return useQuery({
        queryKey: [`useHostLogs`, pageNumber],
        queryFn: () => fetchFunction(hostName, pageNumber, pageSize),
    });
}
