import { debounce } from 'radash';

// Context
import { useAllInstances, useInstanceContext } from '@/context/InstanceContext';

// MUI
import CircularProgress from '@mui/material/CircularProgress';

// Types
import type { IServerInstance } from '@/api/tango/database';
import type { IDataFetchState } from '@/state/types';

// SCSS
import './RefreshButton.scss';

/**
 * Facade for refresh button.
 *
 * @return for refetching all instances.
 */
const useRefreshButton = () => {
    const currentInstances = useAllInstances();
    const { refetch, dispatchInstances } = useInstanceContext();

    async function refresh() {
        const setLoading: IDataFetchState<IServerInstance[]> = {
            ...currentInstances,
            isLoading: true,
        };
        dispatchInstances(setLoading);

        const refetchData = await refetch();
        if (refetchData.data) {
            const newInstances: IDataFetchState<IServerInstance[]> = {
                data: refetchData.data,
                error: refetchData.error,
                isLoading: refetchData.isLoading,
            };
            dispatchInstances(newInstances);
        }
    }

    return { refresh, loading: currentInstances.isLoading };
};

/**
 * Refresh button to refetching all servers instances
 */
const RefreshButton = () => {
    const { refresh, loading } = useRefreshButton();

    const onClick = debounce({ delay: 300 }, refresh);
    return (
        <button onClick={onClick} className="refreshButton">
            {loading ? <CircularProgress /> : <>Refresh</>}
        </button>
    );
};

export default RefreshButton;
