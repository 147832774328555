import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

// Polka state
import { appStateDispatchContext } from '@/state/AppState';

// API
import { getAllInstances } from '@/api/tango/database';

// Constants
import { DB_GET_ALL_INSTANCES_TIMING } from '@/data/constants';

// Types
import type { IServerInstance } from '@/api/tango/database';
import type { IDataFetchState } from '@/state/types';

/**
 * Facade for fetching instance off all Tango servers
 */
export const useInstanceContext = () => {
    const dispatch = useContext(appStateDispatchContext);
    const dispatchInstances = (instances: IDataFetchState<IServerInstance[]>) =>
        dispatch({ type: 'CHANGE_INSTANCE_LIST', newInstances: instances });

    // Periodically fetch
    const query = useQuery({
        queryKey: ['useInstanceContext'],
        queryFn: async () => await getAllInstances(),
        refetchInterval: DB_GET_ALL_INSTANCES_TIMING,
    });

    return { ...query, dispatchInstances };
};
