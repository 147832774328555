import { useQuery } from '@tanstack/react-query';

// API
import { getThreadInfo } from '@/api/tango/server/getThreadInfo/getThreadInfo';

const fetchFunction = async (serverName: string) => {
    return getThreadInfo(serverName);
};

/**
 *
 * @param serverName
 * @returns
 */
export function useThreadManager(serverName: string) {
    return useQuery({
        queryKey: ['useThreadManager'],
        queryFn: () => fetchFunction(serverName),
    });
}
