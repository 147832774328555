import { useQuery } from '@tanstack/react-query';

// API
import * as Database from '@/api/tango/database';

/**
 * Handle fetching database info from Tango DB
 *
 * @returns Tango database info
 */
async function fetchFunction() {
    const dbInfo = await Database.getInfoString();
    return dbInfo.split('\n');
}

/**
 * Hook to fetch information about current Tango Database
 *
 * @returns Parsed array of info
 */
export function useDbInfo() {
    return useQuery({
        queryKey: ['useDbInfo'],
        queryFn: () => fetchFunction(),
    });
}
