import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

// API
import { logout } from '@/api/auth/logout';

// Utils
import { getCurrentDB } from '@/utils/database';

// Context
import {
    ChangeSessionContext,
    ChangeTokenExtendIntervalContext,
    TokenExtendIntervalContext,
} from '@/context/Session';

// SCSS
import './LogOut.scss';

interface ILogOutProps {
    user: string | null;
}

/**
 * Display current logged in user and logout button
 */
const LogOut = ({ user }: ILogOutProps) => {
    const tokenExtendInterval = useContext(TokenExtendIntervalContext);
    const changeTokenExtendInterval = useContext(
        ChangeTokenExtendIntervalContext,
    );

    const changeCurrentSession = useContext(ChangeSessionContext);
    const queryClient = useQueryClient();

    // Stop extending token
    const stopTokenExtend = () => {
        if (tokenExtendInterval) {
            clearInterval(tokenExtendInterval);
            changeTokenExtendInterval(null);
        }
    };

    const handleClick = () => {
        logout().then(() => {
            changeCurrentSession({ isLoggedIn: false, data: null });
            stopTokenExtend();
            queryClient.invalidateQueries();

            const databaseName = getCurrentDB();
            window.location.href = databaseName ? `/${databaseName}` : '/';
        });
    };

    return (
        <div className="log-info">
            {user}
            <button className="logOut-button" onClick={handleClick}>
                Log out
            </button>
        </div>
    );
};

export default LogOut;
