import PrettyDate from '@/components/PrettyDate/PrettyDate';
import { PrettyTime } from '@/components/PrettyTime/PrettyTime';

interface IStandardDateProps {
    date: Date | number | string;
    isSeconds?: boolean;
}

/**
 * Display given date and time in a nice and pretty way
 *
 * @param seconds The seconds from statistics
 */
const StandardDatetime = ({ date, isSeconds }: IStandardDateProps) => {
    return (
        <>
            <PrettyDate date={date} isSeconds={isSeconds} />
            <p style={{ display: 'inline' }}> </p>
            <PrettyTime date={date} isSeconds={isSeconds} />
        </>
    );
};

export default StandardDatetime;
