import { useMemo } from 'react';

// Components
import StandardDate from '@/components/StandardDatetime/StandardDatetime';
import ManuallyPaginatedTable from '@/components/Table/ManuallyPaginatedTable';

// Types
import type { IFetchLogsResponseDTO, ILog } from '@/api/mongo/logs/types';
import type {
    ColumnDef,
    PaginationState,
    SortingState,
} from '@tanstack/react-table';

interface ILogViewerProps {
    pagination: PaginationState;
    sorting: SortingState;
    response: IFetchLogsResponseDTO;
    setPagination: (pagination: PaginationState) => void;
    setSearch: (search: SortingState) => void;
    isLoading: boolean;
}

const LogViewer = ({
    pagination,
    sorting,
    response,
    setPagination,
    setSearch,
    isLoading,
}: ILogViewerProps) => {
    const totalLogs = response.device?.totalRecordCount ?? 0;
    const logs = response.device?.userActions ?? [];
    const pageCount = Math.ceil(totalLogs / pagination.pageSize);

    const columns = useMemo<ColumnDef<ILog>[]>(
        () => [
            {
                accessorKey: 'timestamp',
                header: 'Time',
                sortDescFirst: true,
                cell: (info) => {
                    const dateString = info.getValue<string>();
                    return <StandardDate date={dateString} />;
                },
            },
            {
                accessorKey: 'user',
                header: 'User',
                sortUndefined: 'last',
            },
            {
                accessorKey: 'actionType',
                header: 'Action type',
                sortUndefined: 'last',
            },
            {
                accessorKey: 'name',
                header: 'Command',
                sortUndefined: 'last',
            },
            {
                accessorKey: 'argin',
                header: 'Arguments',
                sortUndefined: 'last',
            },
        ],
        [],
    );

    return (
        <ManuallyPaginatedTable<ILog>
            columns={columns}
            pagination={pagination}
            sorting={sorting}
            data={logs}
            pageCount={pageCount}
            totalRows={totalLogs}
            onPaginationChange={setPagination}
            onSortingChange={setSearch}
            isLoading={isLoading}
        />
    );
};

export default LogViewer;
