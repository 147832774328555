import { useQuery } from '@tanstack/react-query';

// API
import * as TangoHost from '@/api/tango/host';

// Lib
import { parseResponseBody } from './libUseStatistics';

/**
 * Handle fetching statistics form Tango Starter
 *
 * @param hostName The name of the Starter
 *
 * @returns Parsed raw Starter statistics
 */
const fetchFunction = async (hostName: string) => {
    const response = await TangoHost.getStarterStatistics(hostName);

    if (!response.ok) {
        const combinedMessage = response.message.join('; ');
        throw new Error(`DevReadLog: ${combinedMessage}`);
    }

    const rawLogString = response.output;
    return parseResponseBody(rawLogString);
};

/**
 * Hook that is used to fetch starter statistics
 *
 * @param hostName The name of the starter
 *
 * @returns data, error object and loading indicator
 */
export function useStatistics(hostName: string) {
    return useQuery({
        queryKey: ['useStatistics'],
        queryFn: async () => await fetchFunction(hostName),
    });
}
