import { gqlRequestCommand } from '@/api/gql/request';
import { parseStarterLogsResponse } from './libGetStarterLogs';

/**
 * Get Starter logs
 *
 * Fetches logs from a Tango starter by executing the "DevReadLogs" command.
 * The return output looks like this:
 *
 * 31/08/23   10:04:41\tStarter startup\n31/08/23   10:04:02\tStarter startup\n
 * and so on...
 *
 * @param host - The hostname of the Tango starter.
 * @returns An array of parsed logs.
 */
export async function getStarterLogs(host: string) {
    const fullHostName = `tango/admin/${host}`;
    const command = 'DevReadLog';
    const argin = 'Starter';

    const { executeCommand: response } = await gqlRequestCommand(
        fullHostName,
        command,
        argin,
    );

    // This Starter command return string in output
    if (response.ok) return parseStarterLogsResponse(response.output as string);
    throw new Error(`${command}: ${response.message}`);
}
